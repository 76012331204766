import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Card from '../components/Card'
import appsStyles from './apps.module.scss'
import { OutboundLink } from "gatsby-plugin-google-analytics"


const Apps = () => {
    return (
        <Layout className={appsStyles.Layout}>
            <h1 className={appsStyles.title}>Push It: 30 Day Push Ups Challenge</h1>
            <h2>Are you tired of not being fit enough to lift your granny?</h2>
            <h3>Get the app today that will take your push up game to the next level.</h3>
            <div className="CardGroup">
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-pushit-1.jpg")} />
                </div>
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-pushit-2.jpg")} />
                </div>
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-pushit-3.jpg")} />
                </div>
                <div className="Cards">
                    <Card image={require("../images/screenshots/screen-pushit-4.jpg")} />
                </div>
            </div>
            <OutboundLink href="https://apps.apple.com/us/app/push-it-30-days-of-pushups/id1508699133?ls=1" target="_blank" rel="noopener noreferrer"><img src={require('../images/logo-appStore.png')} height="50"></img></OutboundLink>
        </Layout>
    )
}

export default Apps
